<template>
    <div class='organicAcid'>
        <div class="userHead">
            <div class="logo">
                <div class="headImg">
                    <img class="img" :src="headUrl" alt="">
                </div>
            </div>
            <div class="basicData">
                <div class="userinfo">
                    <div class="infoItem" v-for="(item, index) in userData" :key="index">
                        <div class="label">
                            <div class="tipIcon"></div>
                            <div>{{item.label}}</div>
                        </div>
                        <div>{{userInfoData[item.value]}}</div>
                    </div>
                </div>
                <div class="userinfo">
                    <div class="infoItem" v-for="(item, index) in userData2" :key="index">
                        <div class="label">
                            <div class="tipIcon"></div>
                            <div>{{item.label}}</div>
                        </div>
                        <div>{{userInfoData[item.value]}}</div>
                    </div>
                </div>
            </div>
            <div class="paging">
                <div class="pagingImg">
                    <img class="img" :src="pagUrl" alt="">
                </div>
            </div>
        </div>
        <div class="analysis" v-for="(item, index) in analysisData" :key="index">
            <div v-if="item.title">
                <div class="title">{{item.title}}</div>
                <div class="line1"></div>
                <div class="line2"></div>
            </div>
            <div class="content">
                <div class="subtitle">{{item.subtitle}}</div>
                <div class="info">
                    <div v-for="(tel, i) in item.content" :key="i">
                        <div v-if="tel.type == 'text'" class="textMod">
                            <div v-for="(val, k) in tel.info" :key="k">{{val}}</div>
                        </div>
                        <div v-if="tel.type == 'picture'" class="pictureMod">
                           <div class="picUrl">
                                <img class="img" :src="tel.url" alt="">
                           </div>
                        </div>
                        <div v-if="tel.type == 'table'" class="tableMod">
                            <div class="tabTitle">{{tel.title}}</div>
                            <div class="tabnotes">{{tel.notes}}</div>
                            <div class="table">
                                <div class="th">
                                    <div class="tr tr1">不平衡指标</div>
                                    <div class="tr tr2">不平衡程度</div>
                                </div>
                                <div class="tabtd" v-for="(val, y) in tel.tableData" :key="y" :style="{'color': val.fontColor}">
                                    <div class="left tr" v-if="val.show">
                                        <div :style="{'color': val.fontColor}">{{val.TargetName}}</div>
                                        <div class="translate">{{val.translate}}</div>
                                    </div>
                                    <div class="right tr" v-if="val.show">
                                        <div v-if="('text' in val)">
                                            {{val.text}}
                                        </div>
                                        <div class="degree" v-else :style="{'backgroundImage': `linear-gradient(to right, ${val.color1}, ${val.color2})`, 'width': val.Ex2}"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="tips">
                                {{tel.Tips}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCoreReport } from '@/api/index'
import { sortArr, unique } from '@/util/validate'

export default {
  components: {

  },
  data () {
    return {
      highpro: `${this.$imgDomain}/www/HealthingCore/highpro.png`,
      lowpro: `${this.$imgDomain}/www/HealthingCore/lowpro.png`,
      headUrl: `${this.$imgDomain}/www/HealthingCore/organicacid.png`,
      pagUrl: `${this.$imgDomain}/www/HealthingCore/paging.png`,
      indball: `${this.$imgDomain}/www/HealthingCore/indball.png`,
      userInfoData: {},
      userData: [
        {
          label: '姓名：',
          value: 'Ex1'
        },
        {
          label: '性别：',
          value: 'Ex3'
        },
        {
          label: '出生年月：',
          value: 'Ex4'
        }
      ],
      userData2: [
        {
          label: '注册日期：',
          value: 'CreateTime'
        },
        {
          label: '登记编号：',
          value: 'DataValue'
        }
      ],
      analysisData: [
        {
          title: '尿液有机酸代谢分析',
          subtitle: '细胞营养与能量代谢分析-解说指引',
          content: [
            {
              type: 'text',
              info: [
                '细胞营养与能量代谢分析就好比汽车的排放测试：',
                '检验排气来看引擎燃烧燃料的效率如何。同样的，您的尿液中有一些称为「有机酸」的成分能揭露您身体运转的效率。'
              ]
            },
            {
              type: 'text',
              info: [
                '许多有机酸是从您身体将食物转化成为能量、生长、维持及修护组织的代谢途径或化学反应而来。就像汽车引擎中用来点烧燃料的火星塞，维生素和其他必需营养素是这些提供代谢作用能源的化学反应之必需品。每一秒都有成千上万的这类反应在您的体内发生，它们也是您维持健康与活力的基础。',
                '以下的图示说明正常进行的代谢途径。A分子会经由AB酶转化成B分子。B分子会经由BC酶转化成C分子，整个代谢途径就是以这样的方式进行。很多酶需要营养素如特定维生素和矿物质才能发挥作用将一个分子转化成另一个。'
              ]
            },
            {
              type: 'picture',
              url: `${this.$imgDomain}/www/HealthingCore/organicacid1.png`
            },
            {
              type: 'text',
              info: [
                '如果特定的营养素摄入不足，原本应该出现的重要反应将不会出现。以下的图标显示如果营养素不足，AB酶无法有效作用时会发生什么事。只有少量的A分子转化成B分子，剩下的则堆积起来然后流失到尿液中。请留意C分子到D分子的下游也受到影响。'
              ]
            },
            {
              type: 'picture',
              url: `${this.$imgDomain}/www/HealthingCore/organicacid2.png`
            },
            {
              type: 'text',
              info: [
                '请将这个过程视为水坝阻挡水流-只有非常少量的水流得以流向下游，上游则出现泛滥的情形。全套代谢健康评估测量您尿液中某些泛滥的有机酸来判断您的代谢途径是否因为营养不良或其它因素而遭到阻挡。您的报告有多项有机酸指数偏高意指某些营养素的不足可能威胁您的健康。其他有机酸源自于您曾接触过的毒素，还有一些显示您的身体是如何对这些毒素做出反应。如果您的全套代谢健康评估中这些有机酸的检验值偏高，您可以通过提高身体解毒能力来改善。少数几项有机酸是在检验值偏低的时候才有意义；这些会在以后讨论。您的医师可以依据这份报告设计出个人营养补充计划以满足您独特的生化需求。'
              ]
            }
          ]
        },
        {
          title: '尿液有机酸代谢分析',
          subtitle: '细胞线粒体能量生成代谢',
          content: [
            {
              type: 'table',
              title: '',
              tableData: []
            },
            {
              type: 'table',
              title: '',
              tableData: []
            },
            {
              type: 'table',
              title: '',
              tableData: []
            }
          ]
        },
        {
          subtitle: '参考值说明',
          content: [
            {
              type: 'table',
              tableData: [],
              Tips: ''
            }
          ]
        },
        {
          title: '尿液有机酸代谢分析',
          subtitle: '细胞线粒体能量生成代谢',
          content: [
            {
              type: 'picture',
              url: `${this.$imgDomain}/www/HealthingCore/organicacid3.png`
            }
          ]
        },
        {
          title: '详细指标列表',
          subtitle: '脂肪酸代谢标记',
          content: [
            {
              type: 'table',
              tableData: [
                {
                  TargetName: '己二酸',
                  translate: 'Adipate',
                  text: '酯类转化异常，容易形成皮下脂肪堆积，减肥难'
                },
                {
                  TargetName: '辛二酸',
                  translate: 'Suberate',
                  text: '脂肪抗氧化能力下降，容易皮下堆积，且影响脑部脂肪合成，导致记忆力下降'
                },
                {
                  TargetName: '乙基丙二酸',
                  translate: 'Ethylmalonate',
                  text: '肠道菌群失衡，过量的油脂就会在肠道堆积，导致腹部偏胖，排便不畅等'
                }
              ]
            }
          ]
        },
        {
          subtitle: '碳水化合物代谢标记',
          content: [
            {
              type: 'table',
              tableData: [
                {
                  TargetName: '丙酮酸',
                  translate: 'Pyruvate',
                  text: '抗氧化，流失不平衡会导致糖分酵解出现异常，容易肥胖，人会导致疲劳、情绪低落等症状，常见生酮饮食'
                },
                {
                  TargetName: '乳酸',
                  translate: 'Lacate',
                  text: '为人体供能，异常会导致疲劳、乏力，身体酸痛感较强'
                },
                {
                  TargetName: 'β-羟基丁酸',
                  translate: 'BHBA',
                  text: '影响情志，异常会导致嗜睡、冲动等症状'
                }
              ]
            }
          ]
        },
        {
          subtitle: '线粒体能量生成标记',
          content: [
            {
              type: 'table',
              tableData: [
                {
                  TargetName: '柠檬酸',
                  translate: 'Citrate',
                  text: '不平衡表示可能缺乏足够的铁、谷胱甘肽等，又或是由于毒性金属(铝、汞、砷、锑)等影响了代谢酶的活性，导致代谢无法顺利进行'
                },
                {
                  TargetName: '顺式乌头酸',
                  translate: 'Cis-aconitate',
                  text: '和氧化压力有关，体内自由基过多，运动太少'
                },
                {
                  TargetName: '异柠檬酸',
                  translate: 'Isocitrate',
                  text: '不平衡表示(B3、镁、锰)不足或重金属铝过多导致代谢无法顺利进行'
                },
                {
                  TargetName: 'α-酮戊二酸',
                  translate: 'AKG',
                  text: '脂肪酸的分解下降，肝功能下降'
                },
                {
                  TargetName: '琥珀酸',
                  translate: 'Succinate',
                  text: '不平衡表示造成代谢功能损害；或是菌群失衡，泌尿系统感染'
                },
                {
                  TargetName: '富马酸',
                  translate: 'Succinate',
                  text: '是能量供应的关键，不平衡表示能量转换差，辅酶不足或是重金属及毒性元素影响到了辅酶活性，导致代谢通路受阻'
                },
                {
                  TargetName: '苹果酸',
                  translate: 'Malate',
                  text: '是能量供应关键，同时协助营养进入粒线体，不平衡表示营养供应障碍'
                },
                {
                  TargetName: '羟甲基戊二酸',
                  translate: 'HMG',
                  text: '不平衡会导致胆固醇的升高，从而增加高血脂症的患病风险'
                }
              ]
            }
          ]
        },
        {
          subtitle: '细胞线粒体能量生成指标',
          content: [
            {
              type: 'table',
              tableData: [
                {
                  TargetName: '脂肪酸代谢不佳',
                  translate: 'Poor fatty acid metabolism',
                  text: '细胞无法有效的将脂肪燃烧成能量，可能会导致：①、肉碱(L-carnitine)缺乏；②、减肥效果不佳；③、体力不佳；④、认知功能衰退',
                  show: true
                },
                {
                  TargetName: '碳水化合物代谢不佳',
                  translate: 'Poor carbohy- drate metabolism',
                  text: '细胞无法有效的将碳水化合物燃烧成能量，可能会导致：①、酒精、毒性暴露或贫血；②、组织细胞呈现缺氧状态③、运动过量造成乳酸积聚；④、体力不佳',
                  show: true
                },
                {
                  TargetName: '细胞能量生成反应不佳',
                  translate: 'Poor cell energy g- eneration response',
                  text: '将营养成分转换成能量的代谢过程效率不佳，可能会导致：①、疲劳，活动力下降；②、氨毒素（ammonia)清除能力下降③、意识不清，记忆力不佳；④、自由基过量⑤、加速老化；⑥、儿童发育迟缓',
                  show: true
                }

              ]
            }
          ]
        }
      ],
      rawData: [
        {
          name: '己二酸',
          translate: 'Adipate',
          MainId: '脂肪酸代谢标记'
        },
        {
          name: '辛二酸',
          translate: 'Suberate',
          MainId: '脂肪酸代谢标记'
        },
        {
          name: '乙基丙二酸',
          translate: 'Ethylmalonate',
          MainId: '脂肪酸代谢标记'
        },
        {
          name: '丙酮酸',
          translate: 'Pyruvate',
          MainId: '碳水化合物代谢标记'
        },
        {
          name: '乳酸',
          translate: 'Lacate',
          MainId: '碳水化合物代谢标记'
        },
        {
          name: 'β-羟基丁酸',
          translate: 'BHBA-β',
          MainId: '线粒体能量生成标记'
        },
        {
          name: '柠檬酸',
          translate: 'Citrate',
          MainId: '线粒体能量生成标记'
        },
        {
          name: '顺式乌头酸',
          translate: 'Cis-aconitate',
          MainId: '线粒体能量生成标记'
        },
        {
          name: 'α-酮戊二酸',
          translate: 'AKG-α',
          MainId: '线粒体能量生成标记'
        },
        {
          name: '琥珀酸',
          translate: 'Succinate',
          MainId: '线粒体能量生成标记'
        },
        {
          name: '富马酸',
          translate: 'Fumarate',
          MainId: '线粒体能量生成标记'
        },
        {
          name: '苹果酸',
          translate: 'Malate',
          MainId: '线粒体能量生成标记'
        },
        {
          name: '羟甲基戊二酸',
          translate: 'HMG',
          MainId: '线粒体能量生成标记'
        }
      ]
    }
  },
  props: {

  },
  created () {
    this.getOrganicReport()
  },
  mounted () {

  },
  methods: {
    getOrganicReport () {
      const formData = new FormData()
      const autoid = '33454'
      formData.append('action', 'GetUserNCoreExpands')
      formData.append('autoid', autoid)
      getCoreReport(formData).then(res => {
        const { result, status } = res
        if (status) {
          // 用户信息
          this.userInfoData = result.userExpands
          result.coreReportInfo.map(item => {
            item.TargetName = item.Ex3
            item.porp = Number(item.Ex2.split('%')[0])
            if (item.MainId.indexOf('2') != -1) {
              item.MainId = item.MainId.split('2')[0]
            }
          })

          // 数据缺少处理
          this.rawData.forEach(el => {
            const Index = result.coreReportInfo.findIndex((item) => { return item.Ex3 === el.name })
            if (Index == -1) {
              result.coreReportInfo.push({
                Ex2: '100%',
                Target: '正常',
                TargetName: el.name,
                MainId: el.MainId
              })
            }
          })
          this.dataProcessing(result.coreReportInfo)
        } else {
          this.$toast('暂无报告数据')
        }
      })
    },
    dataProcessing (data) {
      data.sort((a, b) => {
        return b.porp - a.porp
      })

      const mergeData2 = sortArr(data, 'MainId')
      mergeData2.forEach(el => {
        el.forEach(item => {
          if (item.porp >= 80) {
            item.color1 = '#FAF0B1'
            item.color2 = '#F5E062'
            item.fontColor = '#D89F15'
            item.show = true
          } else {
            item.color1 = '#B7CDA1'
            item.color2 = '#6E9B42'
            item.show = true
          }
          this.rawData.map(vla => {
            if (item.TargetName == vla.name) {
              item.translate = vla.translate
            }
          })
        })
      })
      this.translationProce(mergeData2)
      this.indicatorInterpretation()
    },
    translationProce (data) {
      data.map(item => {
        item.sort((a, b) => {
          return b.porp - a.porp
        })
        if (item.length < 2) {
          // 营养代谢
          const nutrient = this.analysisData[2].content[0]
          nutrient.title = item[0].MainId
          nutrient.tableData = item
        } else {
          if (item[0].MainId == '脂肪酸代谢标记') {
            // 脂肪酸代谢标记
            const FatData = this.analysisData[1].content[0]
            FatData.title = item[0].MainId
            FatData.tableData = item
          } else if (item[0].MainId == '碳水化合物代谢标记') {
            // 碳水化合物代谢标记
            const carbonWater = this.analysisData[1].content[1]
            carbonWater.title = item[0].MainId
            carbonWater.tableData = item
          } else {
            // 线粒体能量生成标记
            const mitochondrion = this.analysisData[1].content[2]
            mitochondrion.title = item[0].MainId
            mitochondrion.tableData = item
          }
        }
      })
    },
    // 指标解释数据处理
    indicatorInterpretation () {
      const metabolism = this.analysisData[1].content
      const fatMeta = this.analysisData[4].content[0].tableData
      const carbon = this.analysisData[5].content[0].tableData
      const mitochondrial = this.analysisData[6].content[0].tableData
      // let fatMeta = this.analysisData[6];
      metabolism.forEach(item => {
        item.tableData.forEach(val => {
          fatMeta.forEach(el => {
            if ((el.TargetName == val.TargetName) && val.porp >= 80) {
              el.fontColor = '#D89F15'
              el.show = true
            }
          })
          carbon.forEach(el => {
            if ((el.TargetName == val.TargetName) && val.porp >= 80) {
              el.fontColor = '#D89F15'
              el.show = true
            }
          })
          mitochondrial.forEach(el => {
            if ((el.TargetName == val.TargetName) && val.porp >= 80) {
              el.fontColor = '#D89F15'
              el.show = true
            }
          })
        })
      })
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.organicAcid {
    padding: 0 10px;
    .userHead {
        .logo {
            display: flex;
            justify-content: center;
            .headImg {
                width: 85%;
                padding-top: 50px;
            }
        }

        .basicData {
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .userinfo {
                border-bottom: 1px solid #ccc;
                display: flex;
                justify-content: space-around;
                width: 90%;
                .infoItem {
                    display: flex;
                    align-items: center;
                    padding-bottom: 5px;
                    margin-top: 10px;
                    .label {
                        display: flex;
                        align-items: baseline;
                        .tipIcon {
                            width: 8px;
                            height: 8px;
                            background-color: #595757;
                            margin-right: 5px;
                        }
                    }

                }
            }
        }
        .paging {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            .pagingImg {
                width: 20%;
            }
        }
    }
    .analysis {
        padding-top: 40px;
        .title {
            font-weight: 700;
            text-align: center;
            font-size: 20px;
            margin: 20px 0 5px 0;
        }
        .line1 {
            width: 100%;
            height: 2px;
            background: #D35950;
        }
        .line2 {
            width: 100%;
            height: 2px;
            background: #47B8D6;
            margin-top: 2px;
        }
        .content {
            margin-top: 10px;
            .subtitle {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                color: #fff;
                font-size: 16px;
                background: #1E91C9;
            }
            .info {
                border: 1px solid #2186B9;
                padding: 10px;
                .textMod {
                    margin-bottom: 20px;
                }
                .pictureMod {
                    margin-bottom: 20px;
                    .picUrl {
                        width: 100%;
                    }
                }
                .tableMod {
                    margin-bottom: 20px;
                    .tabTitle {
                        font-weight: 700;
                        font-size: 14px;
                    }
                    .tabnotes {
                        font-size: 14px;
                    }
                    .table {
                        border: 1px dashed #ccc;
                        border-bottom: none;
                        margin: 5px 0;
                        .tr {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                            padding: 10px 0;
                            border-top: 1px dashed #ccc;
                            border-bottom: 1px dashed #ccc;
                        }
                        .th {
                            display: flex;
                            .tr1 {
                                width: 30%;
                            }
                            .tr2 {
                                width: 70%;
                                border-left: 1px dashed #ccc;
                            }
                        }
                        .tabtd {
                            display: flex;
                            .left {
                                width: 30%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                .translate {
                                    text-align: center;
                                }
                            }
                            .right {
                                width: 70%;
                                display: flex;
                                border-left: 1px dashed #ccc;
                                align-items:flex-start;
                                padding: 0 5px;
                                .degree {
                                    height: 20px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
